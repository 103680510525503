<template>
  <section>
    <TitleBar tabsParent="backoffice">
      Backoffice
      <template #toolbar-content>
        <b-button-group>
          <b-button
            class="rounded-left"
            variant="outline-primary"
            @click="load()"
          >
            <b-icon-arrow-counterclockwise></b-icon-arrow-counterclockwise>
            Rafraichir
          </b-button>
          <b-button
            class="rounded-right"
            variant="primary"
            @click="openExecutionDialog()"
          >
            <b-icon-gear-wide></b-icon-gear-wide>
            Ex&eacute;cuter...
          </b-button>
        </b-button-group>
        &nbsp;
        <TableButtonGroup
          :tableKey="tableKey"
          @rechercher="rechercher"
        ></TableButtonGroup>
      </template>
    </TitleBar>
    <b-container fluid>
      <ErrorComponent :error="error" @hide="error = null"></ErrorComponent>
      <PaginatedTable
        :fields="fields"
        :busy="busy"
        :pageable="pageable"
        :page="page"
        @change="pageableChanged"
      >
        <template #cell(startTime)="{ item }">
          <DateTimeComponent :value="item.startTime"></DateTimeComponent>
        </template>
        <template #cell(endTime)="{ item }">
          <DateTimeComponent :value="item.endTime"></DateTimeComponent>
        </template>
        <template #cell(exitCode)="{ item }">
          <b-icon-check-circle-fill
            v-if="item.exitCode === 'COMPLETED'"
            variant="success"
          ></b-icon-check-circle-fill>
          <b-icon-question-circle-fill
            v-if="item.exitCode === 'UNKNOWN'"
          ></b-icon-question-circle-fill>
          <b-icon-x-circle-fill
            v-if="!['COMPLETED', 'UNKNOWN'].includes(item.exitCode)"
            variant="danger"
          ></b-icon-x-circle-fill>
        </template>
        <template #cell(actions)="row">
          <b-button pill size="sm" variant="primary" @click="row.toggleDetails">
            D&eacute;tails
          </b-button>
        </template>
        <template #row-details="row">
          <LogJobComponent
            :item="row.item"
            @close="row.toggleDetails"
          ></LogJobComponent>
        </template>
      </PaginatedTable>
      <BatchExecutionDialog ref="batchExecutionDialog"></BatchExecutionDialog>
    </b-container>
  </section>
</template>

<script>
import TitleBar from "../../components/TitleBar.vue";
import TableButtonGroup from "../../components/controls/TableButtonGroup.vue";
import ErrorComponent from "../../components/ErrorComponent.vue";
import PaginatedTable from "../../components/PaginatedTable.vue";
import DateTimeComponent from "../../components/DateTimeComponent.vue";
import LogJobComponent from "../../components/backoffice/LogJobComponent.vue";
import BatchExecutionDialog from "../../components/dialogs/BatchExecutionDialog.vue";
import BackofficeService from "../../services/backoffice.service";
import { TableKeys } from "../../store/tables.defaults";
import {
  dataFactory as tableDataFactory,
  created,
  computed as tableComputed,
  methods as tableMethods,
} from "../../services/tables.service";
import UtilsService from "../../services/utils.service";
export default {
  name: "GestionTraitementsView",
  components: {
    TitleBar,
    TableButtonGroup,
    ErrorComponent,
    PaginatedTable,
    DateTimeComponent,
    LogJobComponent,
    BatchExecutionDialog,
  },
  data() {
    return {
      ...tableDataFactory(TableKeys.BATCHS),
      error: null,
      page: null,
    };
  },
  computed: {
    ...tableComputed,
  },
  created,
  mounted() {
    this.load();
  },
  methods: {
    ...tableMethods,
    async load() {
      this.error = null;
      try {
        this.busy = true;
        let payload = this.getSearchPayload();
        let response = await BackofficeService.getJobsSessions(payload);
        this.page = response.data;
      } catch (error) {
        this.page = null;
        this.error = UtilsService.handleError(error);
      } finally {
        this.busy = false;
      }
    },
    loadJobSessionSteps(jobId) {
      return BackofficeService.getJobSessionSteps(jobId).then((response) => {
        let job = this.page.content.find((j) => j.batchId === jobId);
        job.steps = response.data;
      });
    },
    async openExecutionDialog() {
      await this.$refs.batchExecutionDialog.show();
      this.load();
    },
  },
};
</script>
