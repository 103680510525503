<template>
  <b-modal ref="modal" scrollable @close="cancel">
    <template #modal-title>Ex&eacute;cuter un batch</template>
    <ErrorComponent :error="error" @hide="error = null"></ErrorComponent>
    <SelectBatchComponent
      v-model="selectedBatch"
      :all="true"
    ></SelectBatchComponent>
    <template #modal-footer>
      <b-container fluid>
        <b-row align-h="between">
          <b-col>
            <b-button block pill variant="outline-primary" @click="cancel">
              Annuler
            </b-button>
          </b-col>
          <b-col>
            <b-button
              block
              pill
              variant="danger"
              :disabled="!selectedBatch"
              @click="validate"
            >
              Ex&eacute;cuter
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </b-modal>
</template>

<script>
import ErrorComponent from "../ErrorComponent.vue";
import SelectBatchComponent from "../inputs/SelectBatchComponent.vue";
import BackofficeService from "../../services/backoffice.service";
import UtilsService from "../../services/utils.service";
export default {
  name: "BatchExecutionDialog",
  components: { ErrorComponent, SelectBatchComponent },
  data: () => ({
    selectedBatch: null,
    error: null,
    resolvePromise: null,
    rejectPromise: null,
  }),
  methods: {
    show() {
      this.selectedBatch = null;
      this.error = null;
      this.$refs["modal"].show();
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    async validate() {
      this.error = null;
      try {
        if (this.selectedBatch?.endsWith("Job")) {
          await BackofficeService.executeJob(this.selectedBatch);
        } else {
          await BackofficeService.executeStep(this.selectedBatch);
        }
      } catch (e) {
        this.error = UtilsService.handleError(e);
      }
      this.$refs["modal"].hide();
      this.resolvePromise(true);
    },
    cancel() {
      this.$refs["modal"].hide();
      this.resolvePromise(false);
    },
  },
};
</script>
