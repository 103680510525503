<template>
  <section>
    <ErrorComponent :error="error" @hide="error = null"></ErrorComponent>
    <LogBatchComponent :item="item">
      <b-table
        id="paginated-table"
        sort-icon-left
        :fields="fields"
        :items="steps"
        sort-by="startTime"
        :sort-desc="false"
      >
        <template #cell(startTime)="{ item }">
          <DateTimeComponent :value="item.startTime"></DateTimeComponent>
        </template>
        <template #cell(endTime)="{ item }">
          <DateTimeComponent :value="item.endTime"></DateTimeComponent>
        </template>
        <template #cell(exitCode)="{ item }">
          <b-icon-check-circle-fill
            v-if="item.exitCode === 'COMPLETED'"
            variant="success"
          ></b-icon-check-circle-fill>
          <b-icon-question-circle-fill
            v-if="item.exitCode === 'UNKNOWN'"
          ></b-icon-question-circle-fill>
          <b-icon-x-circle-fill
            v-if="!['COMPLETED', 'UNKNOWN'].includes(item.exitCode)"
            variant="danger"
          ></b-icon-x-circle-fill>
        </template>
        <template #cell(actions)="row">
          <b-button pill size="sm" variant="primary" @click="row.toggleDetails">
            D&eacute;tails
          </b-button>
        </template>
        <template #row-details="row">
          <LogBatchComponent :item="row.item"></LogBatchComponent>
        </template>
      </b-table>
    </LogBatchComponent>
  </section>
</template>

<script>
import ErrorComponent from "../ErrorComponent.vue";
import DateTimeComponent from "../DateTimeComponent.vue";
import LogBatchComponent from "./LogBatchComponent.vue";
import BackofficeService from "../../services/backoffice.service";
import UtilsService from "../../services/utils.service";
export default {
  name: "LogJobComponent",
  components: { ErrorComponent, DateTimeComponent, LogBatchComponent },
  props: ["item"],
  watch: {
    item: "load",
  },
  data() {
    return {
      error: null,
      fields: [
        {
          key: "batchName",
          label: "Step",
          sortable: true,
          sortBy: "batch_name",
        },
        {
          key: "startTime",
          label: "Début",
          sortable: true,
          sortBy: "start_time",
        },
        {
          key: "endTime",
          label: "Fin",
          sortable: true,
          sortBy: "end_time",
        },
        {
          key: "exitCode",
          label: "Statut de fin",
          sortable: true,
          sortBy: "exit_code",
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
      steps: [],
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      BackofficeService.getJobSessionSteps(this.item.batchId)
        .then((response) => {
          this.steps = response.data;
        })
        .catch((error) => {
          this.steps = [];
          this.error = UtilsService.handleError(error);
        });
    },
  },
};
</script>
