<template>
  <b-form-group :id="`group-${id}`" :label="label" :label-for="`select-${id}`">
    <b-form-select
      v-if="options"
      :id="`select-${id}`"
      :name="`select-${id}`"
      :value="value"
      :options="options"
      @input="onInput"
      @change="onChange"
    ></b-form-select>
  </b-form-group>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
export default {
  name: "SelectBatchComponent",
  /**
   * value = valeur du select
   * all = true pour ajouter une option "Toutes"
   */
  props: ["value", "all"],
  watch: {
    all: "load",
  },
  data() {
    return {
      id: `batch-${uuidv4()}`,
      label: "Batch",
      options: null,
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.options = await this.$store.dispatch("ref/getBatchs", {
        all: this.all,
      });
    },
    onInput($event) {
      return this.$emit("input", $event);
    },
    onChange($event) {
      return this.$emit("change", $event);
    },
  },
};
</script>
