<template>
  <b-card>
    <b-card-title>
      {{ item.batchName }} (session #{{ item.batchId }})
    </b-card-title>
    <b-card-sub-title>
      Statut courant : {{ item.status }} - Statut final :
      {{ item.exitCode }}
    </b-card-sub-title>
    <b-card-text v-if="item.exitMessage">
      {{ item.exitMessage }}
    </b-card-text>
    <b-card-text>
      <b-row>
        <b-col>{{ item.readCount }} reads</b-col>
        <b-col>{{ item.readSkipCount }} read skips</b-col>
      </b-row>
      <b-row>
        <b-col>{{ item.filterCount }} filters</b-col>
        <b-col>{{ item.processSkipCount }} process skips</b-col>
      </b-row>
      <b-row>
        <b-col>{{ item.writeCount }} writes</b-col>
        <b-col>{{ item.writeSkipCount }} write skips</b-col>
      </b-row>
      <b-row>
        <b-col>{{ item.commitCount }} commits</b-col>
        <b-col>{{ item.rollbackCount }} rollbacks</b-col>
      </b-row>
    </b-card-text>
    <b-card-text>
      <slot></slot>
    </b-card-text>
  </b-card>
</template>

<script>
export default {
  name: "LogBatchComponent",
  props: ["item"],
};
</script>
